/*
- Drop Outline node in the Paper Editor
*/
import _has from 'lodash/has';
import config from '@/config';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import router from '@/router';
import store from '@/store';
import {v4 as uuidv4} from 'uuid';
import Vue from 'vue';

CKEDITOR.plugins.add('paperDropOutlineNode', {
	init(editor) {
		editor.on('paste', (evt)=>{
			let outlineNodeFull = evt.data.dataTransfer.getData('outlineNodeFull');
			if (!outlineNodeFull) {
				return;
			}
		
			// does this outline node have a citation?
			if(
				_has(outlineNodeFull, 'citation') &&
				(
					(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 && _has(outlineNodeFull.citation, 'apa7') && _has(outlineNodeFull.citation.apa7, 'first')) ||
					(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9 && _has(outlineNodeFull.citation, 'mla9') && _has(outlineNodeFull.citation.mla9, 'first')) ||
					(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 && _has(outlineNodeFull.citation, 'turabian9') && _has(outlineNodeFull.citation.turabian9, 'first'))
				)
			){
				let replaceCitationUniqueId = outlineNodeFull.citation.citationData.citationUniqueID;
				
				let newlyGeneratedCitationUniqueId = uuidv4().toUpperCase();
				outlineNodeFull.citation.citationData['citationUniqueID'] = newlyGeneratedCitationUniqueId;
				store.commit('paperEdit/citations/ADD_TO_PAPER', outlineNodeFull.citation);
				
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					store.commit('paperEdit/footnotes/ADD_TO_PAPER', {
						citationUids: [newlyGeneratedCitationUniqueId],
						content: '<p>@@@</p>',
					});
				}
				
				evt.data.dataValue = outlineNodeFull.content;

				// after the citation is inserted update the values (this pays off when you have a group author with abbreviation and subsequent citations)
				Vue.nextTick(()=>{
					// inject the citation unique id on the element just injected
					let $citationNode = null;
					$citationNode = editor.document.findOne('span[replace-citation-unique-id="' + replaceCitationUniqueId + '"]');


					if($citationNode){
						$citationNode.setAttribute('citation-unique-id', newlyGeneratedCitationUniqueId);
						$citationNode.setAttribute('data-citation-unique-id', newlyGeneratedCitationUniqueId);
						$citationNode.removeAttribute('replace-citation-unique-id');
					}
				});

			}//e:if





			Vue.nextTick(()=>{
				PS_BuildReferencesInPaper().then(()=>{
					if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						store.commit('paperEdit/outline/ADD_ACTIVE_NODE', outlineNodeFull.id);
						// CKEDITOR.instances['ckPaperBody'].execCommand('autocorrect');
						// CKEDITOR.instances['ckPaperBody'].execCommand('autocorrect');
					}
				});
			});
		});
	}
});
